import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { PaginationResp } from "@app/pages/search/service";
import { environment as env } from "@environments/environment";
import {
  ITask,
  ITaskBoard,
  ITaskFilter,
  ITaskUser,
  TaskReqBody,
  TaskReqResp,
} from "./task.model";

@Injectable({ providedIn: "root" })
export class TaskService {
  readonly #http = inject(HttpClient);

  getTasks(opts: ITaskFilter = { limit: 10, page: 1 }) {
    if (opts.filters?.statuses) {
      // HACK: BE expected statusIds to be an numbers, not strings
      opts.filters.statuses = opts.filters.statuses.map(statusId => {
        return Number(statusId) as unknown as string;
      });
    }
    return this.#http.post<TaskReqResp.SearchTasks>(
      `${env.apiUrl}/tasks/search`,
      opts,
      {
        withCredentials: true,
      }
    );
  }

  getTaskById(taskId: string) {
    return this.#http.get<ITask>(`${env.apiUrl}/tasks/${taskId}`, {
      withCredentials: true,
    });
  }

  createTask(body: TaskReqBody.CreateTask) {
    type Resp = { task: ITask };
    return this.#http.post<Resp>(`${env.apiUrl}/tasks`, body, {
      withCredentials: true,
    });
  }

  patchTask(id: string, body: TaskReqBody.PatchTask) {
    type Resp = ITask;
    return this.#http.patch<Resp>(`${env.apiUrl}/tasks/${id}`, body, {
      withCredentials: true,
    });
  }

  deleteTask(id: string) {
    return this.#http.delete<void>(`${env.apiUrl}/tasks/${id}`, {
      withCredentials: true,
    });
  }

  getTaskBoards() {
    type Resp = { taskBoards: ITaskBoard[] } | null;
    return this.#http.get<Resp>(`${env.apiUrl}/board-tasks`, {
      withCredentials: true,
    });
  }

  createTaskBoard(board: TaskReqBody.CreateNewBoard) {
    type Resp = { taskBoard: ITaskBoard };
    return this.#http.post<Resp>(`${env.apiUrl}/task-boards`, board, {
      withCredentials: true,
    });
  }

  patchTaskBoard(id: string, body: TaskReqBody.PatchNewBoard) {
    type Resp = { taskBoard: ITaskBoard };
    return this.#http.patch<Resp>(`${env.apiUrl}/task-boards/${id}`, body);
  }

  deleteTaskBoard(id: string) {
    return this.#http.delete<void>(`${env.apiUrl}/task-boards/${id}`, {
      withCredentials: true,
    });
  }

  getAvailableTaskList(id: string) {
    return this.#http.get(`${env.apiUrl}/tasks/${id}/lists`, {
      withCredentials: true,
    });
  }

  getTaskTypes() {
    type Resp = { taskTypes: Array<ITask["taskType"]> } | null;
    return this.#http.get<Resp>(`${env.apiUrl}/task-types`, {
      withCredentials: true,
    });
  }

  getTaskStatuses() {
    type Resp = Array<{ id: number; name: string }> | null;
    return this.#http.get<Resp>(`${env.apiUrl}/status-lists/group`, {
      withCredentials: true,
    });
  }

  getAssignedusers() {
    type Resp = PaginationResp<ITaskUser[]> | null;
    const opts = { limit: 100, page: 1 }; // TODO: this shouldn't be static
    return this.#http.post<Resp>(`${env.apiUrl}/employees/search`, opts, {
      withCredentials: true,
    });
  }

  uploadAttachments(payload: TaskReqBody.UploadAttachments) {
    type Resp = TaskReqResp.UploadAttachments;

    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("Accept", "application/json");

    const { attachments, propertyId, modelId, modelType } = payload;
    const body = new FormData();
    body.append("modelId", modelId);
    body.append("modelType", modelType);
    body.append("propertyId", propertyId);
    for (const file of attachments) {
      body.append("attachments[]", file, file.name);
    }

    return this.#http.post<Resp>(`${env.apiUrl}/attachments`, body, {
      withCredentials: true,
      headers,
    });
  }

  getTaskAttachments(taskId: string) {
    type Resp = { taskTypes: Array<ITask["taskType"]> } | null;
    return this.#http.get<Resp>(`${env.apiUrl}/attachments/${taskId}`, {
      withCredentials: true,
    });
  }
}
